<template>
  <div class="wc-PageView wc_myactivities">
    <div
      class="wc-PageView_ContentContainer wc-ModulePageChangePageData_ContentContainer"
    >
      <div>
        <div class="nh-NavigationHeaderModule nh-NavigationHeaderModule-wide">
          <div class="nh-NavigationHeaderModule_Title">
            {{ $t("finance_text11") }}
          </div>
        </div>
      </div>
      <div>
        <div class="container">
          <div class="panel-container">
            <section class="panel">
              <header class="panel-header panel-header__fullheight mb-0">
                <div class="panel-summary">
                  <div class="amount" style="color: #00bc86">
                    {{ $t("cur") }} {{ sum_getback }}
                  </div>

                  <!-- <div class="time-period">{{ begin }}-{{ end }}</div> -->
                </div>

                <div class="panel-title header-tool-tip-container">
                  <h1 class="m-0">{{ $t("invest_text8") }}</h1>
                </div>

                <!-- <h2 class="panel-subtitle">
                  {{ $t("invest_text9") }}
                </h2> -->
              </header>
            </section>

            <section class="panel">
              <header class="panel-header panel-header__fullheight">
                <div class="panel-summary">
                  <div class="amount">{{ $t("cur") }} {{ sum_cost_unit }}</div>
                </div>

                <div class="panel-title header-tool-tip-container">
                  <h1>{{ $t("invest_text10") }}</h1>
                </div>
                <h2 class="panel-subtitle">{{ $t("invest_text11") }}</h2>
              </header>
            </section>

            <section class="panel" v-for="(l, k) in list" :key="k">
              <header class="panel-header panel-header__fullheight mb-0">
                <div class="panel-summary">
                  <div class="amount">
                    {{ parseFloat((l.cost * l.unit).toFixed(2)) }}
                  </div>
                </div>

                <div class="panel-title header-tool-tip-container">
                  <h1 class="m-0">
                    {{ l.cname }}&nbsp;{{ $t("invest_text12") }}
                  </h1>
                </div>
              </header>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  name: "Home",
  data() {
    return {
      sum_getback: 0,
      sum_cost_unit: 0,
      list: [],
    };
  },
  watch: {},
  methods: {
    Repoort: function () {
      let param = {
        action: "etf_holding",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          this.sum_getback = res.sum_getback;
          this.sum_cost_unit = res.sum_cost_unit;
          this.list = res.data;
        }
      });
    },
  },
  created() {
    this.Repoort();
  },
};
</script>
