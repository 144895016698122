<template>
  <div class="container home2">
    <div class="row">
      <maintop1 />
      <maintop2 />
      <div :class="flag_nav?'main_fix_offset col-xl-10':'main_fix_width col-xl-12'" class="header_mt">
        <WalletView v-if="Id == 'wallet'" />
        <Myacc v-if="Id == 'myacc'" />
        <HistoryView v-if="Id == 'history'" />
        <ActivityView v-if="Id == 'activity'" />
        <MessageView v-if="Id == 'message'" />
        <InvestView v-if="Id == 'invest'" />
        <myfinanceView v-if="Id == 'finance'" />
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import "@/assets/style/_bank.scss";
import "@/assets/style/_bank_into.scss";
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import maintop1 from "./Main_top1.vue";
import maintop2 from "./Main_top2.vue";
import Footer from "./Home/Footer.vue";
import WalletView from "./Wallet";
import Myacc from "./Myacc.vue";
import HistoryView from "./History.vue";
import ActivityView from "./Activity.vue";
import MessageView from "./Message.vue";
import InvestView from "./myinvest.vue";
import myfinanceView from "./myfinance.vue";

export default {
  name: "login",
  setup() {},
  data() {
    return {
      code: Cookies.get("code"),
    };
  },
  computed: {
    ...mapGetters(["list", "flag_nav"]),
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
  },
  components: {
    Footer,
    maintop1,
    maintop2,
    WalletView,
    Myacc,
    HistoryView,
    ActivityView,
    MessageView,
    InvestView,
    myfinanceView
  },
  watch: {},
  created() {
    if (this.code == null) {
      this.$router.push("/Login");
    }
  },
  mounted() {},
  methods: {},
};
</script>
