<template>
  <div class="mx-auto mb-5" style="max-width: 1200px; padding-top: 30px">
    <div class="d-flex w-100">
      <div>
        <h1 class="help_title">{{ $t("footer_p") }}</h1>
        <div v-html="$t('privacy_con')"></div>
      </div>
    </div>
  </div>
</template>
