<template>
  <div>
    <Loading :active="isLoading" :can-cancel="true">
      <img
        src="@/assets/img/main/35771931234507.564a1d2403b3a.gif"
        class="w-100"
      />
    </Loading>
    <router-view />
    <pop></pop>
    <ann></ann>
    <addcard></addcard>
    <img
      @click="link()"
      v-if="
        $store.state.service.qq.qq != '' && $store.state.service.qq.qq != null
      "
      src="@/assets/img/main/pre_service.png"
      class="position-absolute end-0 service_img"
    />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import pop from "./views/Home/pop.vue";
import ann from "./views/Home/announce.vue";
import addcard from "./views/Home/addcard.vue";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  created() {
    document.title = "Lucky777 | " + this.$t("text_web_title");
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  components: {
    Loading,
    pop,
    ann,
    addcard,
  },

  methods: {
    link: function () {
      var url = "";
      url = this.$store.state.service.qq.qq;
      window.open(url);
    },
  },
};
</script>
