<template>
  <div>
    <div class="d-lg-flex justify-content-between">
      <div
        class="d-flex flex-column me-lg-2 text-center p-2 rounded-3 mt-2 bg_main_blue col-12 col-lg-6 h-100"
      >
        <div class="row justify-content-center p-1">
          <div class="col-6 home_left_img d-block"></div>
          <div
            class="col-6 pb-xl-3 pt-md-3 d-flex justify-content-around flex-column text-start"
          >
            <p class="fw-bold fs_24">{{ $t("text_total").toUpperCase() }}</p>
            <p class="fw-bold fs_28" style="color: #feac31">{{ vir_total.toLocaleString() }}</p>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column me-lg-2 text-center p-2 rounded-3 mt-2 bg_main_blue col-12 col-lg-6 h-100"
      >
        <div class="row justify-content-center p-1">
          <div class="col-6 home_left_img1 d-block"></div>
          <div
            class="col-6 pb-xl-3 pt-md-3 d-flex justify-content-around flex-column text-start"
          >
            <p class="fw-bold fs_24">{{ $t("home_text4").toUpperCase() }}</p>
            <p class="fw-bold fs_28" style="color: #feac31">{{ vir_player.toLocaleString() }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      css_list: "col-6 mb-2 px-lg-2 px-1",
      vir_total: Math.ceil(Math.random() * 89999999 + 10000000),
      vir_player: Math.ceil(Math.random() * 89999 + 10000),
    };
  },
  methods: {},
  mounted() {},
  created() {},
  components: {},
};
</script>
