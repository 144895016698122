<template>
  <div class="d-flex flex-wrap">
    <div class="share_div fw-bolder p-3 text-center col-12">
      {{ $t("home_text2") }}
    </div>
    <div v-if="!flag_next">
      <div
        class="share_div2 d-flex p-3 text-center col-12 justify-content-around"
      >
        <span
          :class="title_val == 1 ? 'sh_title' : ''"
          @click="title_val = 1"
          >{{ $t("main_share_sub1") }}</span
        >
        <span
          :class="title_val == 2 ? 'sh_title' : ''"
          @click="title_val = 2"
          >{{ $t("main_share_sub2") }}</span
        >
        <span
          :class="title_val == 3 ? 'sh_title' : ''"
          @click="title_val = 3"
          >{{ $t("main_share_sub3") }}</span
        >
      </div>
      <p class="px-2 col-12 mt-3 mb-2 fw-bolder">
        {{ $t("main_share_text1") }}
      </p>
      <div class="col-12 d-flex" style="overflow-x: auto">
        <div
          v-for="(l, k) in imgList"
          :key="k + 1"
          class="col-xl-4 col-12 p-2 border_radius_26 position-relative"
          :class="title_img == k + 1 ? 'advert_border' : ''"
          @click="title_img = k + 1"
        >
          <img :src="l" class="w-100 border_radius_26" />
          <div class="img_code_1">
            <vue-qrcode :value="shareurl" />
          </div>
        </div>
      </div>
      <p class="px-2 col-12 my-2">{{ $t("main_share_text2") }}</p>
      <div
        class="col-12 sh_border mb-3 ps-2 border_radius_26"
        :class="title_text == k + 1 ? 'advert_border' : ''"
        @click="title_text = k + 1"
        v-for="(l, k) in share_more_content"
        :key="k + 1"
      >
        {{ l.content }}
      </div>
      <div class="col-12 text-center">
        <button
          class="border-0 text-white bg_green_btn p-2 w-50"
          @click="flag_next = true"
        >
          <span>{{ $t("text_next") }}</span>
        </button>
      </div>
    </div>
    <share2
      v-else
      :title="title_val"
      :title_img="imgList[title_img - 1]"
      :title_text="share_more_content[title_text - 1].content"
      :shareurl="shareurl"
    />
  </div>
</template>
<script>
import share2 from "./share_2.vue";
import VueQrcode from "vue-qrcode";
export default {
  props: {
    shareurl: String,
    share_more_content: Array,
  },
  data() {
    return {
      imgList: [
        require("@/assets/img/share/gameweb_ad1.jpg"),
        require("@/assets/img/share/gameweb_ad2.jpg"),
      ],
      // conList: ["人人发财", "领介绍佣金", "无限三级分佣模式"],
      title_val: 1,
      title_img: 1,
      title_text: 1,
      flag_next: false,
    };
  },
  methods: {},
  mounted() {},
  created() {},
  components: {
    share2,
    VueQrcode,
  },
};
</script>
