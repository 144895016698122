<template>
  <div v-show="isann && list != ''">
    <div class="announcement-dialog">
      <div class="modal-overlay modal-show"></div>
      <div class="modal-announcement modal-show">
        <div class="ma-title">
          <span
            role="img"
            aria-label="close-circle"
            class="close-circle"
            @click="this.$store.commit('isann', false)"
            ><svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="close-circle"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"
              ></path>
              <path
                d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
              ></path></svg
          ></span>
          <span style="line-height: 25px">{{ $t("notice") }}</span>
        </div>
        <div class="ma-body">
          <section class="ma-sidebar">
            <ul class="ul_none">
              <li
                v-for="(l, k) in list"
                :key="k"
                :class="{ active: k == anncurrent }"
                @mouseover="anncurrent = k"
              >
                <svg
                  class="svg-inline--fa fa-comment-dots fa-w-16 d-none d-xl-inline"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="comment-dots"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 480 8 480c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32zM128 272c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 0c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 0c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"
                  ></path></svg
                ><span class="ng-binding">{{ l.pname }}</span
                ><svg
                  class="svg-inline--fa fa-chevron-right fa-w-10 d-none d-xl-inline"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fa"
                  data-icon="chevron-right"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                  ></path>
                </svg>
              </li>
            </ul>
          </section>
          <span class="ma-content-wrap">
            <div
              style="height: 560px"
              v-html="l.detail"
              class="ma-content"
              v-for="(l, k) in list"
              :key="k"
              v-show="k == anncurrent"
            ></div>
          </span>
        </div>
        <div class="ma-footer"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import "@/assets/style/modal.css";
export default {
  data() {
    return {
      list: [],
      anncurrent: 0,
    };
  },
  computed: {
    ...mapGetters(["isann"]),
  },
  methods: {
    announce_fun: function () {
      let param = {
        action: "ptt",
        body: {},
      };
      this.$store.dispatch("getpost", param).then((res) => {
        res = res
          .replaceAll("\n", "")
          .replaceAll("\r", "")
          .replaceAll("\r\n", "");

        this.list = JSON.parse(res)["data"];
      });
    },
  },
  watch: {},
  created() {
    this.announce_fun();
  },
};
</script>
