<template>
  <div style="align-items: center">
    <img :src="src" :style="Style" />
    <p style="font-size: 15px; font-weight: 700">
      {{ Cname }}
    </p>
  </div>
</template>
<script>
export default {
  props: { cname: String, mpkidx: String },
  computed: {
    src: function () {
      var name =
        this.cname.toLowerCase().indexOf("gcash") != -1
          ? "gcash"
          : this.cname.toLowerCase().indexOf("paymaya") != -1
          ? "paymaya"
          : this.cname;
      var img = "";
      switch (name) {
        case "gcash":
          img = "gcash";
          break;
        case "paymaya":
          img = "paymaya";
          break;
        default:
          img = "bank_card";
          break;
      }
      return require("@/assets/img/main/" + img + ".png");
    },
    Style: function () {
      var style = "";
      switch (this.mpkidx) {
        default:
          style = "width: 70px;height: 70px";
          break;
      }
      return style;
    },
    Cname: function () {
      var str = this.fullChar2halfChar(String(this.cname)).lastIndexOf("(");
      var _cname = this.cname.substring(str + 1, String(this.cname).length - 1);
      if (str != -1) {
        return _cname;
      } else {
        return this.cname;
      }
    },
  },
  methods: {
    fullChar2halfChar: function (str) {
      var result = "";
      for (var i = 0; i < str.length; i++) {
        var code = str.charCodeAt(i); //獲取當前字元的unicode編碼
        if (code >= 65281 && code <= 65373) {
          //在這個unicode編碼範圍中的是所有的英文字母已經各種字元
          result += String.fromCharCode(str.charCodeAt(i) - 65248); //把全形字元的unicode編碼轉換為對應半形字元的unicode碼
        } else if (code == 12288) {
          //空格
          result += String.fromCharCode(str.charCodeAt(i) - 12288 + 32);
        } else {
          result += str.charAt(i);
        }
      }
      return result;
    },
  },
};
</script>
