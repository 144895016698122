<template>
  <div>
    <div class="prewindow_div"></div>
    <div class="bighead_bg">
      <div class="d-flex flex-wrap p-2 p-xl-3">
        <div class="col-3 col-xl-2 p-2 p-xl-3" v-for="l in list" :key="l">
          <img
            :src="require('@/assets/img/big_head/' + l + '.png')"
            class="w-100"
            @click="SetLogo(l)"
          />
        </div>
      </div>

      <div class="bump_close" @click="close_big">
        <span
          role="img"
          aria-label="close-circle"
          class="anticon anticon-close-circle"
          ><svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="close-circle"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"
            ></path>
            <path
              d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
            ></path></svg
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      list: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22,
      ],
    };
  },
  computed: {},
  methods: {
    SetLogo: function (e) {
      let param = {
        action: "set_logo",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          logo: e,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          this.$emit("flag_val", false, e);
          this.$MSG.API_popmsg(res.status.toUpperCase(), "", "success");
        } else {
          this.$MSG.API_popmsg(res.msg, "", "error");
        }
      });
    },
    close_big: function () {
      this.$emit("flag_val", false, localStorage.getItem("big_logo"));
    },
  },
  watch: {},
  created() {},
};
</script>
