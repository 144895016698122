import { createI18n } from "vue-i18n";
import en from "./en-US.json";
import ms from "./ms-my.json";
import de from "./de.json";
import es from "./es.json";
import fi from "./fi.json";
import fr from "./fr.json";
import ital from "./ital.json";
import por from "./por.json";
import vi from "./vi.json";
import py from "./py.json";
import ar from "./ar.json";
import per from "./per.json";
import ti from "./ti.json";
import jp from "./jp.json";
import cn from "./cn.json";
import ko from "./ko.json";
var locale = localStorage.getItem("locale") ?? process.env.VUE_APP_LANG;

if (locale != null && locale != "" && locale != "en") {
  sessionStorage.setItem("Json", JSON.stringify(locale));
} else {
  sessionStorage.setItem("Json", JSON.stringify(en));
  localStorage.setItem("locale", process.env.VUE_APP_LANG);
}

const i18n = createI18n({
  legacy: false,
  locale: locale,
  fallbackLocale: process.env.VUE_APP_LANG,
  messages: {
    en: en,
    ms: ms,
    de: de,
    es: es,
    fi: fi,
    fr: fr,
    ital: ital,
    por: por,
    vi: vi,
    py: py,
    ar: ar,
    per: per,
    ti: ti,
    jp: jp,
    cn: cn,
    ko: ko,
  },
});

export default i18n;
