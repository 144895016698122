import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import i18n from "./i18n/index";
import Utils from "./components/utils/utils";
import MSG from "./components/http/msgbox";

import store from "./store";

import VueSweetalert2 from "vue-sweetalert2";
import "@/assets/style/sweetalert2.min.css";
import VueClipboard from "vue-clipboard2";

import VueTelInput from "vue-tel-input";
import "@/assets/style/vue-tel-input.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "@/assets/style/swiper.css";
import "@/assets/style/all.scss";
import "@/assets/style/dark.scss";
import lazyPlugin from "vue-lazyload";

const globalOptions = {
  mode: "auto",
};

const app = createApp(App);
app.config.globalProperties.$Utils = Utils;
app.config.globalProperties.$MSG = MSG;

// var isMobile = Utils.isMobile();
var u = new URL(window.location.href);
localStorage.setItem("agent", u.searchParams.get("agent") ?? "");

var introducer =
  window.location.href.indexOf("?ref") != -1
    ? window.location.href
        .split("?")[1]
        .split("&")[0]
        .split("#")[0]
        .replace("#", "")
        .replace("/", "")
    : "";
localStorage.setItem("introducer", introducer);

// var url = document.location.origin;
// if (isMobile == "pc端") {
//   location.href = "https://www.kk996.cc";
// } else {
// }

app
  .use(i18n)
  .use(VueTelInput, globalOptions)
  .use(VueSweetalert2)
  .use(VueClipboard)
  .use(store)
  .use(router)
  .use(lazyPlugin, {
    loading: require("@/assets/logo_big.png"), // 图片加载时默认图片
    error: require("@/assets/logo_big.png"), // 图片加载失败时默认图片
  })
  .mount("#app");
