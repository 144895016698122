<template>
  <div class="content_img">
    <img
      class="col-xl-4 col-6"
      v-lazy="require('@/assets/img/free/1.png')"
      @click="
        free_window(
          'https://gamestaging.12live.vip/avengers/?wmode=tf&lang=en&gameId=43&token=eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjkwOTE1ODUiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiRlVOXzBiNDEyZDZiY2NlNTRkODQ5NzA1YzVhNWQ0ZThhMjU2IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS9hY2Nlc3Njb250cm9sc2VydmljZS8yMDEwLzA3L2NsYWltcy9pZGVudGl0eXByb3ZpZGVyIjoiQVNQLk5FVCBJZGVudGl0eSIsIkFzcE5ldC5JZGVudGl0eS5TZWN1cml0eVN0YW1wIjoiZTYxN2FlNWMtYmYzYS00NmI1LTk5ODItM2NlODBiMDcyYjU3IiwiTGFzdE5hbWUiOiJGVU5fMGI0MTJkNmJjY2U1NGQ4NDk3MDVjNWE1ZDRlOGEyNTYiLCJGaXJzdE5hbWUiOiJGVU5fMGI0MTJkNmJjY2U1NGQ4NDk3MDVjNWE1ZDRlOGEyNTYiLCJQYXJlbnRJZCI6IjkwODk3NTIiLCJQYXJlbnRVc2VyTmFtZSI6IkZVTiIsIkFnZW50TGV2ZWwiOiIzIiwiQ3VycmVuY3kiOiJGVU4iLCJuYmYiOjE2ODk4Mzc1NDksImV4cCI6MTY5MDAxMDM0OSwiaXNzIjoibW0tYXBpLmNvbSIsImF1ZCI6IjQxNGUxOTI3YTM4ODRmNjhhYmM3OWY3MjgzODM3ZmQxIn0.s7eBFTmrW10_1XTWZT-wbk9sAA7HP4gKY5oo5hK-npk '
        )
      "
    />
    <img
      class="col-xl-4 col-6"
      v-lazy="require('@/assets/img/free/2.png')"
      @click="
        free_window(
          'https://gamestaging.12live.vip/lol/?wmode=tf&lang=en&gameId=54&token=eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjkwOTE1ODIiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiRlVOXzNkYjFjMDQ4NTZmYjQzNDY4YjRhZjVlY2U3Nzc1ODMyIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS9hY2Nlc3Njb250cm9sc2VydmljZS8yMDEwLzA3L2NsYWltcy9pZGVudGl0eXByb3ZpZGVyIjoiQVNQLk5FVCBJZGVudGl0eSIsIkFzcE5ldC5JZGVudGl0eS5TZWN1cml0eVN0YW1wIjoiY2YwZmJhOGEtYjE3Ny00MjBkLTgyNWYtYjNkNzlmYjI2NjY2IiwiTGFzdE5hbWUiOiJGVU5fM2RiMWMwNDg1NmZiNDM0NjhiNGFmNWVjZTc3NzU4MzIiLCJGaXJzdE5hbWUiOiJGVU5fM2RiMWMwNDg1NmZiNDM0NjhiNGFmNWVjZTc3NzU4MzIiLCJQYXJlbnRJZCI6IjkwODk3NTIiLCJQYXJlbnRVc2VyTmFtZSI6IkZVTiIsIkFnZW50TGV2ZWwiOiIzIiwiQ3VycmVuY3kiOiJGVU4iLCJuYmYiOjE2ODk4Mzc0NjcsImV4cCI6MTY5MDAxMDI2NywiaXNzIjoibW0tYXBpLmNvbSIsImF1ZCI6IjQxNGUxOTI3YTM4ODRmNjhhYmM3OWY3MjgzODM3ZmQxIn0.WPEpNdAnHuVPMS24abFMIC51jVolZrXmznxQpVibDDo '
        )
      "
    />
    <img
      class="col-xl-4 col-6"
      v-lazy="require('@/assets/img/free/3.png')"
      @click="
        free_window(
          'https://multi-cdn.cg11pro.com/demo/StreetFighterV/?gameMode=1&language=en&mask=false '
        )
      "
    />
    <img
      class="col-xl-4 col-6"
      v-lazy="require('@/assets/img/free/4.png')"
      @click="
        free_window(
          'https://gamestaging.12live.vip/squidgame/?wmode=tf&lang=en&gameId=47&token=eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjkwOTE1ODMiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiRlVOXzM4NjM5MTljNzAxMDQxNjI5YTJlY2RjN2NhZWYyZjBhIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS9hY2Nlc3Njb250cm9sc2VydmljZS8yMDEwLzA3L2NsYWltcy9pZGVudGl0eXByb3ZpZGVyIjoiQVNQLk5FVCBJZGVudGl0eSIsIkFzcE5ldC5JZGVudGl0eS5TZWN1cml0eVN0YW1wIjoiZDQwYzA0MGMtMWIxMi00MDA1LTk2YWEtODc3NTQ5MDcxZjQ2IiwiTGFzdE5hbWUiOiJGVU5fMzg2MzkxOWM3MDEwNDE2MjlhMmVjZGM3Y2FlZjJmMGEiLCJGaXJzdE5hbWUiOiJGVU5fMzg2MzkxOWM3MDEwNDE2MjlhMmVjZGM3Y2FlZjJmMGEiLCJQYXJlbnRJZCI6IjkwODk3NTIiLCJQYXJlbnRVc2VyTmFtZSI6IkZVTiIsIkFnZW50TGV2ZWwiOiIzIiwiQ3VycmVuY3kiOiJGVU4iLCJuYmYiOjE2ODk4Mzc0OTksImV4cCI6MTY5MDAxMDI5OSwiaXNzIjoibW0tYXBpLmNvbSIsImF1ZCI6IjQxNGUxOTI3YTM4ODRmNjhhYmM3OWY3MjgzODM3ZmQxIn0._moyE3GH8TSrnFlg69f3RQtB8MsRIsQQSe6mx_WiaxI '
        )
      "
    />
    <img
      class="col-xl-4 col-6"
      v-lazy="require('@/assets/img/free/5.png')"
      @click="
        free_window(
          'https://multi-cdn.cg11pro.com/demo/PikachuVolleyball/index.html?gameMode=1&showHome=true&language=ch&coinSymbol=&homeUrl=https://demo.cg11pro.com/&mask=false '
        )
      "
    />
    <img
      class="col-xl-4 col-6"
      v-lazy="require('@/assets/img/free/6.png')"
      @click="
        free_window(
          'https://multi-cdn.cg11pro.com/demo/plinko/index.html?gameMode=1&showHome=true&language=ch&coinSymbol=&homeUrl=https://demo.cg11pro.com/&mask=false '
        )
      "
    />
  </div>
</template>
<script>
export default {
  methods: {
    free_window: function (e) {
      window.open(e, "_blank");
    },
  },
};
</script>
