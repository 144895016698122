<template>
  <div @click="this.$store.commit('Lang', false)" v-if="islang">
    <div
      class="language_card d-flex align-items-center"
      id="dropdown_lang"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <img
        src="@/assets/img/main/worldwide.png"
        style="width: 25px; height: 25px"
      />
      <!-- <div class="px-1">{{ $t("selectLang") }}</div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path
          d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
        ></path>
      </svg> -->
    </div>

    <ul
      class="dropdown-lang dropdown-menu mt-3 border_eee"
      aria-labelledby="dropdown_lang"
    >
      <li @click="lang('en')"><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/us.svg" class="me-1" style="width:1.66em"/>English</a></li>
      <li @click="lang('ms')"><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/my.svg" class="me-1" style="width:1.66em"/>Melayu</a></li>
      <li @click="lang('de')"><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/de.svg" class="me-1" style="width:1.66em"/>Deutsch</a></li>
      <li @click="lang('es')"><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/es.svg" class="me-1" style="width:1.66em"/>Español</a></li>
      <li @click="lang('fi')"><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/ph.svg" class="me-1" style="width:1.66em"/>Filipino</a></li>
      <li @click="lang('fr')"><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/fr.svg" class="me-1" style="width:1.66em"/>Français</a></li>
      <li @click="lang('ital')"><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/it.svg" class="me-1" style="width:1.66em"/>Italiano</a></li>
      <li @click="lang('por')"><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/pt.svg" class="me-1" style="width:1.66em"/>Português</a></li>
      <li @click="lang('vi')"><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/vn.svg" class="me-1" style="width:1.66em"/>Tiếng Việt</a></li>
      <li @click="lang('py')"><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/ru.svg" class="me-1" style="width:1.66em"/>Русский</a></li>
      <li @click="lang('ar')"><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/ae.svg" class="me-1" style="width:1.66em"/>عربي</a></li>
     
      <li><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/ir.svg" class="me-1" style="width:1.66em"/>فارسی</a></li>
      <li><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/th.svg" class="me-1" style="width:1.66em"/>ภาษาไทย</a></li>
      <li><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/jp.svg" class="me-1" style="width:1.66em"/>日本語</a></li>
      <li><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/cn.svg" class="me-1" style="width:1.66em"/>简体中文</a></li>
      
      <!-- <li @click="lang('per')"><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/ir.svg" class="me-1" style="width:1.66em"/>فارسی</a></li>
      <li @click="lang('ti')"><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/th.svg" class="me-1" style="width:1.66em"/>ภาษาไทย</a></li>
      <li @click="lang('jp')"><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/jp.svg" class="me-1" style="width:1.66em"/>日本語</a></li>
      <li @click="lang('cn')"><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/cn.svg" class="me-1" style="width:1.66em"/>简体中文</a></li> -->
      <!-- <li><a class="dropdown-item" href="#">繁體中文</a></li> -->
      <li><a class="dropdown-item" href="#"><img src="@/assets/img/lang_img/kr.svg" class="me-1" style="width:1.66em"/>한국어</a></li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      locale: localStorage.getItem("locale"),
      islang: true,
    };
  },
  computed: {},
  watch: {
    locale: function (e) {
      localStorage.setItem("locale", e);
      window.location.reload();
    },
  },
  methods: {
    lang: function (e) {
      this.locale = e;
    },
  },
};
</script>
