<template>
  <div>
    <div class="content_img">
      <img v-for="l in list" :key="l" class="col-4" v-lazy="require('@/assets/img/big_head/' + l + '.png')"
        @click="flag_gift_page = true" />
    </div>

    <div class="finance_page" v-show="flag_gift_page">
      <div class="finance_card">
        <div class="finance_close" @click="flag_gift_page = false">×</div>
        <div class="finance_title">
          {{ $t("gift_text1") }}
        </div>

        <div class="finance_main">
          <div class="finance_deposit">
            <div class="d-flex mt-3 align-items-center">
              <span>
                {{ $t("gift_text2") }}：
              </span>
              禮物名稱
            </div>
            <div class="d-flex mt-3 align-items-center">
              <span>
                {{ $t("gift_text3") }}：
              </span>
              <select v-model="giftcontent" class="rounded-5 border-2 border-dark" style="flex:1">
                <option v-for="(l, index) in giftoption" :key="index" :value="index">
                  {{ l }}
                </option>
              </select>
            </div>
            <div class="d-flex mt-3 align-items-center">
              <span>
                {{ $t("gift_text4") }}：
              </span>
              <input type="text" class="finance_text" v-model="payment" style="flex:1" />
              <p v-show="flag_pay_error">
                <span style="color: Red">{{ $t("error_tips") }}</span>
              </p>
            </div>
            <div class="d-flex mt-3 align-items-center">
              <span>
                {{ $t("gift_text5") }}：
              </span>
              888
            </div>
            <div class="col-10 text-white mx-auto my-3">
              <button class="border-0 text-white bg_green_btn p-2 w-100" :disabled="flag_pay_error" :style="flag_pay_error
                ? 'cursor: no-drop;background-color: #CCC;'
                : ''
                ">
                <span>{{ $t("gift_text6") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      list: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22,
      ],
      payment: 0,
      flag_gift_page: false,
      flag_pay_error: false,
      giftoption: ['Red', 'Blue', 'Black'],
      giftcontent: 0
    };
  },
  methods: {
  },
  mounted() { },
  created() {
  },
  watch: {
  },
};
</script>
