<template>
  <div>
    <div
      class="row home_game_arrow home_game_casino"
      v-if="show_id == 'casino'"
    >
      <div class="d-flex justify-content-between">
        <div class="game_logo_img fw-bolder my-3">
          {{ $t("home_text6").toUpperCase() }}
        </div>
        <div class="top_arrow">
          <div class="left_right">
            <button class="btn_arrow" @click="turn('casino', 'left')">
              <span role="img" aria-label="left"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="left"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                  ></path>
                </svg>
              </span></button
            ><button class="btn_arrow" @click="turn('casino', 'right')">
              <span role="img" aria-label="right"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="right"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <swiper
        :loop="swiper_casino_options.loop"
        :speed="swiper_casino_options.speed"
        :slidesPerView="swiper_casino_options.slidesPerView"
        :spaceBetween="swiper_casino_options.spaceBetween"
        :breakpoints="swiper_casino_options.breakpoints"
        :navigation="swiper_casino_options.navigation"
      >
        <!-- <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('web', 'casino', '', 'BG', 'casino')"
              class="game_item"
            >
              <img src="@/assets/img/game/all/live_bg.png" class="w-100" />
              <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >BG</span
                                >
            </div>
          </div>
        </swiper-slide> -->
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('web', 'casino', '', 'YB', 'casino')"
              class="game_item"
            >
              <img src="@/assets/img/game/all/live_yeebet.png" class="w-100" />
              <!-- <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >YB</span
                                > -->
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('web', 'casino', '0', 'WM', 'casino')"
              class="game_item"
            >
              <img src="@/assets/img/game/all/live_wm.png" class="w-100" />
              <!-- <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >WM</span
                                > -->
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('web', 'casino', '', 'EVO', 'casino')"
              class="game_item"
            >
              <img src="@/assets/img/game/all/live_evo.png" class="w-100" />
              <!-- <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >EVO</span
                                > -->
            </div>
          </div>
        </swiper-slide>
        <!-- <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('web', 'casino', 'xg006', 'XG', 'casino')"
              class="game_item"
            >
              <img src="@/assets/img/game/all/live_xg.png" class="w-100" />
            </div>
          </div>
        </swiper-slide> -->
        <!-- <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('', '1', '0', 'AG', 'casino')"
              class="game_item"
            >
              <img src="@/assets/img/game/all/live_ag.png" class="w-100" />
              <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >AG</span
                                >
            </div>
          </div>
        </swiper-slide> -->
        <!-- <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('web', 'casino', '101', 'PP', 'casino')"
              class="game_item"
            >
              <img src="@/assets/img/game/all/live_pp.png" class="w-100" />
              <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >PP</span
                                >
            </div>
          </div>
        </swiper-slide> -->
      </swiper>
    </div>
    <div class="row home_game_arrow home_game_sport" v-if="show_id == 'sport'">
      <div class="d-flex justify-content-between">
        <div class="game_logo_img fw-bolder my-3">
          {{ $t("text_sport") }}
        </div>
        <div class="top_arrow">
          <div class="left_right">
            <button class="btn_arrow" @click="turn('sport', 'left')">
              <span role="img" aria-label="left"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="left"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                  ></path>
                </svg>
              </span></button
            ><button class="btn_arrow" @click="turn('sport', 'right')">
              <span role="img" aria-label="right"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="right"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <swiper
        :loop="swiper_casino_options.loop"
        :speed="swiper_casino_options.speed"
        :slidesPerView="swiper_casino_options.slidesPerView"
        :spaceBetween="swiper_casino_options.spaceBetween"
        :breakpoints="swiper_casino_options.breakpoints"
        :navigation="swiper_casino_options.navigation"
      >
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=3',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img src="@/assets/img/sport/sport_PREMIER.png" class="w-100" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=2;sportid=2;leaguekey=56038',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img src="@/assets/img/sport/sport_NBA.png" class="w-100" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=43',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img src="@/assets/img/sport/sport_LALIGA.png" class="w-100" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=8',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img src="@/assets/img/sport/sport_LIGUE1.png" class="w-100" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=5',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img
                src="@/assets/img/sport/sport_BUNDESLIGA.png"
                class="w-100"
              />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=4',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img src="@/assets/img/sport/sport_SERIEA.png" class="w-100" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=8;sportid=8;leaguekey=127094',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img src="@/assets/img/sport/sport_MLB.png" class="w-100" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=3;sportid=3;leaguekey=127105',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img src="@/assets/img/sport/sport_NFL.png" class="w-100" />
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <newWindow
      :URL="newWindowurl"
      @iframe_close="iframeclosed"
      v-show="this.newWindowurl != ''"
    ></newWindow>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { reactive } from "vue";
//引入swiper
import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, EffectCoverflow, Navigation]);
import newWindow from "./iframe.vue";
import { mapGetters } from "vuex";
// import { mapActions, mapGetters } from "vuex";
export default {
  props: { show_id: String },
  setup() {
    //指定swiper的设置
    let swiper_casino_options = reactive({
      loop: false,
      speed: 500,
      slidesPerView: "auto",
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
    });

    return {
      swiper_casino_options,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    newWindow,
  },
  data() {
    return {
      code: Cookies.get("code"),
      css_list: "position-relative",
      newWindowurl: "",
    };
  },
  methods: {
    // ...mapActions(["getGameList"]),
    // gamelist: function (t) {
    //   this.newVal = 0;
    //   let param = {
    //     action: "GameLobby",
    //     body: {
    //       gametype: t,
    //       type: "all",
    //     },
    //   };
    //   this.getGameList(param)
    //     .then((finalResult) => {
    //       this.index = 1;
    //       this.maxVal = this.list.length > 0 ? this.list.length : 0;
    //       this.newVal = this.list.length > 20 ? 20 : this.list.length;
    //       document.getElementById("app").scrollTop = 0;
    //       console.log(finalResult);
    //     })
    //     .catch();
    // },
    turn(id, e) {
      if (e == "left") {
        document
          .querySelector(".home_game_" + id + " .swiper-button-prev")
          .click();
      } else {
        document
          .querySelector(".home_game_" + id + " .swiper-button-next")
          .click();
      }
    },
    game: function (device, mode, id, type, kind) {
      if (this.code == null || this.code == "") {
        this.$router.push("Login");
        return;
      } else {
        if (id == "Coming Soon") {
          alert(this.$t("text_coming"));
        } else if (id == "GameLobby") {
          localStorage.setItem("g", type);
          this.$router.push({ name: "Game" });
        } else {
          if (type.toUpperCase() == "TCG" || type.toUpperCase() == "SB") {
            var isMobile = this.$Utils.isMobile();
            if (isMobile != "pc端") {
              device = "mobile";
            } else device = "web";
          }

          let param = {
            action: "stage_balance",
            body: {
              uid: localStorage.getItem("uid"),
              ip: localStorage.getItem("ip"),
              session_code: Cookies.get("code"),
              device: device,
              gametype: type,
              gamekind: kind,
              gameid: id,
              mode: mode,
            },
          };
          this.$store.dispatch("getpost", param).then((res) => {
            this.newWindowurl = res;
          });
        }
      }
    },
    iframeclosed(val) {
      this.newWindowurl = val;
    },
  },
  watch: {
    newWindowurl() {
      if (this.newWindowurl == "") {
        this.$emit("watch_newWindowurl", "close");
      }
    },
  },
  computed: {
    ...mapGetters(["list"]),
  },
  mounted() {},
  created() {},
};
</script>
