<template>
  <div class="wc-PageView wc_mybank">
    <div class="wc-PageView_NavigationMenu">
      <div
        class="nm-NavigationMenuModule-footerdisplayed nm-NavigationMenuModule nm-NavigationMenuModule-wide"
      >
        <div class="nm-MenuHeader">
          <div class="nm-MenuHeader_Text">{{ $t("myacc") }}</div>
        </div>
        <div class="nm-MenuItems">
          <div
            id="btn_data"
            class="nm-MenuItem"
            :class="
              this.select == 'acc'
                ? 'nm-MenuItem-selected nm-MenuItem-selectedindicator'
                : ''
            "
            @click="this.select = 'acc'"
          >
            <div class="nm-MenuItem_Text">{{ $t("basic") }}</div>
          </div>
          <div
            id="btn_pas"
            class="nm-MenuItem"
            :class="
              this.select == 'pwd'
                ? 'nm-MenuItem-selected nm-MenuItem-selectedindicator'
                : ''
            "
            @click="this.select = 'pwd'"
          >
            <div class="nm-MenuItem_Text">{{ $t("passwordTitle") }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="wc-PageView_ContentContainer wc-ModulePage_ContentContainer">
      <div class="mybank_first" v-if="this.select == 'acc'">
        <div>
          <div class="nh-NavigationHeaderModule nh-NavigationHeaderModule-wide">
            <div class="nh-NavigationHeaderModule_Title">{{ $t("basic") }}</div>
          </div>
        </div>
        <div>
          <div class="wmeTextBox">
            <div>
              <span class="flexiField_Label paymentnumber-label">{{
                $t("join_date")
              }}</span>
              <input
                type="text"
                class="flexiField_Input"
                style="color: #000"
                v-model="RegTime"
                disabled="disabled"
              />
              <div></div>
            </div>
          </div>
          <div class="wmeTextBox">
            <div>
              <span class="flexiField_Label paymentnumber-label">{{
                $t("reg_userid")
              }}</span>
              <input
                type="text"
                class="flexiField_Input"
                style="color: #000"
                v-model="UserID"
                disabled="disabled"
              />
              <div></div>
            </div>
          </div>

          <div class="wmeTextBox">
            <div>
              <span class="flexiField_Label paymentnumber-label">{{
                $t("text_realNameTitle")
              }}</span>
              <input
                type="text"
                class="flexiField_Input"
                style="color: #000"
                v-model="UserName"
                disabled="disabled"
              />
              <div></div>
            </div>
          </div>
          <div class="wmeTextBox">
            <div>
              <span class="flexiField_Label paymentnumber-label">{{
                $t("reg_phone")
              }}</span>
              <input
                type="text"
                class="flexiField_Input"
                style="color: #000"
                v-model="Mobile"
                disabled="disabled"
              />
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <form method="POST" @submit="changePassword" ref="change_pwd">
        <div class="mybank_second" v-if="this.select == 'pwd'">
          <div>
            <div
              class="nh-NavigationHeaderModule nh-NavigationHeaderModule-wide"
            >
              <div class="nh-NavigationHeaderModule_Title">
                {{ $t("passwordTitle") }}
              </div>
            </div>
          </div>
          <div>
            <div class="wmeTextBox">
              <div>
                <span class="flexiField_Label paymentnumber-label">{{
                  $t("oldpwd")
                }}</span>
                <input
                  type="password"
                  maxlength="12"
                  CssClass="flexiField_Input"
                  :placeholder="this.$t('oldpwd1')"
                  v-model="pwd"
                />
              </div>
            </div>
            <div class="wmeTextBox">
              <div>
                <span class="flexiField_Label paymentnumber-label">{{
                  $t("newpwd")
                }}</span>
                <input
                  type="password"
                  maxlength="12"
                  CssClass="flexiField_Input"
                  :placeholder="this.$t('newpwd1')"
                  v-model="newpwd"
                />
                <p>
                  <span class="tips" style="color: #636363"
                    >{{ $t("p1") }}
                  </span>
                </p>
              </div>
            </div>

            <div class="wmeTextBox">
              <div>
                <span class="flexiField_Label paymentnumber-label">{{
                  $t("comfpwd")
                }}</span>
                <input
                  type="password"
                  maxlength="12"
                  CssClass="flexiField_Input"
                  :placeholder="this.$t('comfpwd1')"
                  v-model="confirpwd"
                />
              </div>
            </div>

            <div class="col-12 text-center">
              <button class="border-0 text-white bg_green_btn p-2 w-50 mt-3 ">
                {{ $t("save_pw_btn") }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  name: "Home",
  computed: {},
  data() {
    return {
      select: "acc",
      pwd: "",
      newpwd: "",
      confirpwd: "",
      RegTime: "",
      UserName: "",
      UserID: "",
      Mobile: "",
    };
  },
  methods: {
    member: function () {
      let param = {
        action: "Member",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.UserID = res.userid;
        this.RegTime = res.opdate;
        this.UserName = res.cname;
        this.Mobile = res.tel;
      });
    },
    changePassword: function (e) {
      e.preventDefault();
      if (this.pwd && this.newpwd && this.confirpwd) {
        if (this.checkform()) {
          let param = {
            action: "ChangePassword",
            body: {
              uid: localStorage.getItem("uid"),
              session_code: Cookies.get("code"),
              password: this.pwd,
              password2: this.confirpwd,
            },
          };
          this.$store.dispatch("getpost", param).then((res) => {
            this.$MSG.API_popmsg(res.msg, "", res.status);
          });
        }
      } else {
        var error_str = "";
        if (!this.pwd)
          error_str +=
            this.$t("oldpwd") + " " + this.$t("error_required") + "</br>";
        if (!this.newpwd)
          error_str +=
            this.$t("newpwd") + " " + this.$t("error_required") + "</br>";
        if (!this.confirpwd)
          error_str +=
            this.$t("comfpwd") + " " + this.$t("error_required") + "</br>";
        this.$MSG.API_popmsg(error_str, "", "error");
      }
    },
    checkform: function () {
      var rules = /^[a-zA-Z0-9]{6,12}$/;
      if (!rules.test(this.pwd) || !rules.test(this.newpwd)) {
        this.$MSG.API_popmsg(this.$t("error_tips"), "", "error");
        return false;
      } else if (this.newpwd !== this.confirpwd) {
        this.$MSG.API_popmsg(
          this.$t("Member_registerCheckPasswordPatternErrMessage"),
          "",
          "error"
        );
        return false;
      } else return true;
    },
  },
  created() {
    this.member();
  },
};
</script>
