<template>
  <div v-if="isaddcard">
    <div class="prewindow_div"></div>
    <div class="add_card" v-if="!open">
      <div class="card_all">
        <div class="card_page">
          <div class="page_main">
            <div class="d-flex justify-content-center">
              <p class="word_white">{{ $t("BankCard_bankAccountTitle") }}</p>
            </div>
            <hr />

            <div class="card_gray mb-3">
              <div class="card_input d-flex align-items-center">
                <p class="mb-0" style="width: 40%">{{ $t("add_Bank") }}：</p>
                <select v-model="select">
                  <option v-for="l in list" :key="l" :value="l">
                    {{ l }}
                  </option>
                </select>
              </div>
            </div>

            <div class="card_gray mb-3">
              <div class="card_input d-flex align-items-center">
                <p class="mb-0" style="width: 40%">{{ $t("add_Account") }}：</p>
                <input type="text" v-model="cname" class="px-2" />
              </div>
            </div>

            <div class="card_gray mb-3">
              <div class="card_input d-flex align-items-center">
                <p class="mb-0" style="width: 40%">{{ $t("add_Card") }}：</p>
                <input type="text" v-model="cardno" class="px-2" />
              </div>
            </div>

            <div class="btn_card d-flex justify-content-center mt-3">
              <div class="download_btn c-pointer" @click="confirm_bank()">
                {{ $t("shared_comfirm") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block_card" v-else>
      <div class="block_header">
        <p>{{ $t("BankCard_pleaseConfirmTitle") }}</p>
      </div>
      <div class="block_content">
        <div class="block_data">
          <p class="text-break">{{ select }}</p>
          <p class="text-break">{{ cname }}</p>
          <p class="text-break">{{ cardno }}</p>
        </div>
        <p>
          {{ $t("BankCard_confirmAccountTips") }}
        </p>
      </div>
      <div class="block_actions d-flex justify-content-center">
        <button
          @click="this.open = false"
          type="button"
          style="
            display: inline-block;
            background-color: rgb(244, 67, 54);
            margin-right: 5px;
            padding: 8px 25px;
            border-radius: 10px;
            color: #fff;
          "
        >
          {{ $t("shared_cancel") }}
        </button>
        <button
          @click="addbankcard"
          type="button"
          style="
            display: inline-block;
            background-color: #00bc86;
            margin-left: 5px;
            padding: 8px 25px;
            border-radius: 10px;
            color: #fff;
          "
        >
          {{ $t("shared_comfirm") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["isaddcard"]),
  },
  components: {},
  data() {
    return {
      code: Cookies.get("code"),
      open: false,
      list: [],
      cname: "",
      cardno: "",
      select: "",
    };
  },
  methods: {
    bankList: function () {
      let param = {
        action: "banklist",
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.list = res.list;
        this.select = this.list[0];
      });
    },
    confirm_bank: function () {
      if (
        String(this.select).length > 0 &&
        String(this.cardno).length > 0 &&
        String(this.cname).length > 0
      ) {
        this.open = true;
      }
    },
    addbankcard: function () {
      if (
        String(this.select).length > 0 &&
        String(this.cardno).length > 0 &&
        String(this.cname).length > 0
      ) {
        let param = {
          action: "addbankcard",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            methods: this.select,
            cardno: this.cardno,
            name: this.cname,
            bankcity: "",
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            localStorage.setItem("first", "");
            this.$store.commit("ADDCARD", false);
            this.$MSG.API_popmsg(res.msg, "addcard", "success");
          } else {
            this.$MSG.API_popmsg(res.msg, "addcard", "fail");
          }
        });
      }
    },
  },
  created() {
    this.bankList();
  },
};
</script>
