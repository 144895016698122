import axios from 'axios';
import store from '@/store/index.js'
import msgbox from './msgbox';
// 創建axios實例
const service = axios.create({
    baseURL:process.env.VUE_APP_BASEURL, // api的base_url
    // withCredentials: true, // 跨域請求時是否發送cookies
    timeout: 60000 ,// 請求超時設置
    headers: { "Content-Type": "application/xml;charset=UTF-8" },
});
const service2 = axios.create({
  baseURL:process.env.VUE_APP_BASEURL, // api的base_url
  // withCredentials: true, // 跨域請求時是否發送cookies
  timeout: 5000 ,// 請求超時設置
  headers: { "Content-Type": "application/xml;charset=UTF-8" },
});
axios.defaults.headers.post["Access-Control-Allow-Origin-Type"] = "*"; // 允許跨域
const err = (error) => {
    if (error.response) {
    //   let data = error.response.data;
    //   const token = store.getters.token;
    var err;
      switch (error.response.status) {
        case 404:
          err=("找不到該頁面");
          break;
        case 500:
          err=("伺服器出錯");
          break;
        case 503:
          err=("服務失效");
          break;
        default:
          err=(`連接錯誤${error.response.status}`);
          break;
      }
    }else {
      err=("連接到服務器失敗");
      }
      store.commit('Loading', false);
      msgbox.resPonseStatus("");
    return Promise.reject(err);
  };
  // request攔截器
  service.interceptors.request.use(

    (config) => {
      store.commit('Loading', true);
      return config;
    },
    (error) => {
      store.commit('Loading', false);
      msgbox.resPonseStatus("");
      // Do something with request error
      // console.log(error); // for debug
      Promise.reject(error);
    }
  );
  service2.interceptors.request.use(

    (config) => {

      return config;
    },
    (error) => {
      // Do something with request error
      // console.log(error); // for debug
      Promise.reject(error);
    }
  );
  // respone攔截器
  service.interceptors.response.use((response) => {
    // console.log("response", response.data);
    store.commit('Loading', false);
    return response.data;
  }, err);
  service2.interceptors.response.use((response) => {
    // console.log("response", response.data);
    return response.data;
  }, err);

  /*
   *  get請求
   *  url:請求地址
   *  params:引數
   * */
  export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
      service({
        url: url,
        method: "get",
        params: params,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  export function get2(url, params = {}) {
    return new Promise((resolve, reject) => {
      service2({
        url: url,
        method: "get",
        headers:params
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  /*
   *  post請求
   *  url:請求地址
   *  params:引數
   * */
  export function post(url, params = {}) {
    return new Promise((resolve, reject) => {
      service({
        url:url,
        method: "post",
        data: params,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  export function post2(url, params = {}) {
    return new Promise((resolve, reject) => {
      service2({
        url: url,
        method: "post",
        data: params,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
 

  export default {
    get,
    get2,
    post,
    post2
  };

